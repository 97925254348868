import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { format } from "date-fns";

export const fetchGlobalNews = createAsyncThunk(
  "globalNews/fetchGlobalNews",
  async (selectedDate = null, { rejectWithValue }) => {
    try {
      const dateToFetch = selectedDate
        ? format(new Date(selectedDate), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd");

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/global-news`,
        {
          params: { date: dateToFetch },
        }
      );

      return {
        news: response.data.data.data,
        whoOutbreaks: response.data.data.who_outbreaks,
        timestamp: response.data.timestamp,
        selectedDate: dateToFetch,
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch news");
    }
  }
);

const globalNewsSlice = createSlice({
  name: "globalNews",
  initialState: {
    news: {},
    whoOutbreaks: [],
    loading: false,
    error: null,
    lastUpdated: null,
    selectedDate: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGlobalNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload.news;
        state.whoOutbreaks = action.payload.whoOutbreaks;
        state.lastUpdated = action.payload.timestamp;
        state.selectedDate = action.payload.selectedDate;
      })
      .addCase(fetchGlobalNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch news";
      });
  },
});

export const { clearError } = globalNewsSlice.actions;
export default globalNewsSlice.reducer;
