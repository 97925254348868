import { format } from "date-fns";

// Constants for PDF styling
const STYLES = {
  MARGINS: {
    LEFT: 20,
    RIGHT: 20,
    TOP: 20,
    BOTTOM: 20,
  },
  COLORS: {
    PRIMARY: [41, 128, 185], // Blue for headers
    SECONDARY: [52, 73, 94], // Dark gray for metadata
    TEXT: [0, 0, 0], // Black for body text
  },
  FONTS: {
    HEADER: "helvetica",
    BODY: "helvetica",
  },
  FONT_SIZES: {
    TITLE: 18,
    HEADER: 14,
    SUBHEADER: 12,
    BODY: 11,
    FOOTER: 9,
  },
  LINE_HEIGHTS: {
    BODY: 6,
    HEADER: 10,
  },
};

/**
 * Adds the WHO section to the PDF document with proper formatting and layout
 * @param {jsPDF} doc - The jsPDF document instance
 * @param {Object} whoReport - The WHO report data
 * @returns {Promise<void>}
 */
export const addWhoSection = async (doc, whoReport) => {
  if (!whoReport) return;

  let currentY = STYLES.MARGINS.TOP;
  const pageWidth = doc.internal.pageSize.width;
  const maxWidth = pageWidth - STYLES.MARGINS.LEFT - STYLES.MARGINS.RIGHT;

  // Add title section
  doc.setFont(STYLES.FONTS.HEADER, "bold");
  doc.setFontSize(STYLES.FONT_SIZES.TITLE);
  doc.setTextColor(...STYLES.COLORS.PRIMARY);
  doc.text("WHO", STYLES.MARGINS.LEFT, currentY);
  currentY += STYLES.LINE_HEIGHTS.HEADER;

  // Add report metadata
  doc.setFont(STYLES.FONTS.BODY, "normal");
  doc.setFontSize(STYLES.FONT_SIZES.BODY);
  doc.setTextColor(...STYLES.COLORS.SECONDARY);

  const reportDate = new Date(whoReport.report_date);
  const metadataText = [
    `Report #${whoReport.report_number}`,
    `Date: ${format(reportDate, "MMMM d, yyyy")}`,
    `Week ${whoReport.week_number}, ${whoReport.year}`,
  ].join(" | ");

  doc.text(metadataText, STYLES.MARGINS.LEFT, currentY);
  currentY += STYLES.LINE_HEIGHTS.HEADER;

  // Helper function to add wrapped text
  const addWrappedText = (text, y) => {
    doc.setFont(STYLES.FONTS.BODY, "normal");
    doc.setFontSize(STYLES.FONT_SIZES.BODY);
    doc.setTextColor(...STYLES.COLORS.TEXT);

    const lines = doc.splitTextToSize(text, maxWidth);
    doc.text(lines, STYLES.MARGINS.LEFT, y);
    return y + lines.length * STYLES.LINE_HEIGHTS.BODY;
  };

  // Add Summary Text
  if (whoReport.summary?.summary_text) {
    doc.setFont(STYLES.FONTS.HEADER, "bold");
    doc.setFontSize(STYLES.FONT_SIZES.HEADER);
    doc.setTextColor(...STYLES.COLORS.PRIMARY);
    doc.text("Summary", STYLES.MARGINS.LEFT, currentY);
    currentY += STYLES.LINE_HEIGHTS.HEADER;

    currentY = addWrappedText(whoReport.summary.summary_text, currentY);
    currentY += STYLES.LINE_HEIGHTS.HEADER;
  }

  // Add Influenza Info
  if (whoReport.summary?.influenza_info) {
    doc.setFont(STYLES.FONTS.HEADER, "bold");
    doc.setFontSize(STYLES.FONT_SIZES.HEADER);
    doc.setTextColor(...STYLES.COLORS.PRIMARY);
    doc.text("Influenza Activity", STYLES.MARGINS.LEFT, currentY);
    currentY += STYLES.LINE_HEIGHTS.HEADER;

    if (whoReport.summary.influenza_info.northern_hemisphere) {
      currentY = addWrappedText(
        "Northern Hemisphere: " +
          whoReport.summary.influenza_info.northern_hemisphere,
        currentY
      );
      currentY += STYLES.LINE_HEIGHTS.HEADER;
    }

    if (whoReport.summary.influenza_info.southern_hemisphere) {
      currentY = addWrappedText(
        "Southern Hemisphere: " +
          whoReport.summary.influenza_info.southern_hemisphere,
        currentY
      );
      currentY += STYLES.LINE_HEIGHTS.HEADER;
    }
  }

  // Add SARS-CoV-2 Info
  if (whoReport.summary?.sars_cov2_info) {
    doc.setFont(STYLES.FONTS.HEADER, "bold");
    doc.setFontSize(STYLES.FONT_SIZES.HEADER);
    doc.setTextColor(...STYLES.COLORS.PRIMARY);
    doc.text("SARS-CoV-2 Activity", STYLES.MARGINS.LEFT, currentY);
    currentY += STYLES.LINE_HEIGHTS.HEADER;

    if (whoReport.summary.sars_cov2_info.activity_info) {
      currentY = addWrappedText(
        whoReport.summary.sars_cov2_info.activity_info,
        currentY
      );
      currentY += STYLES.LINE_HEIGHTS.HEADER;
    }
  }

  // Add Additional Information
  if (whoReport.summary?.raw_text) {
    doc.setFont(STYLES.FONTS.HEADER, "bold");
    doc.setFontSize(STYLES.FONT_SIZES.HEADER);
    doc.setTextColor(...STYLES.COLORS.PRIMARY);
    doc.text("Additional Information", STYLES.MARGINS.LEFT, currentY);
    currentY += STYLES.LINE_HEIGHTS.HEADER;

    currentY = addWrappedText(whoReport.summary.raw_text, currentY);
    currentY += STYLES.LINE_HEIGHTS.HEADER;
  }

  // Add images section
  if (whoReport.images && whoReport.images.length > 0) {
    doc.addPage();
    currentY = STYLES.MARGINS.TOP;

    doc.setFont(STYLES.FONTS.HEADER, "bold");
    doc.setFontSize(STYLES.FONT_SIZES.HEADER);
    doc.setTextColor(...STYLES.COLORS.PRIMARY);
    doc.text("Surveillance Data Visualizations", STYLES.MARGINS.LEFT, currentY);
    currentY += STYLES.LINE_HEIGHTS.HEADER;

    const titles = [
      "Influenza activity: Proportion of specimens testing positive for influenza",
      "SARS-CoV-2 activity: Proportion of specimens testing positive for SARS-CoV-2",
      "Influenza activity: Change in proportion of specimens testing positive for influenza ",
      "SARS-CoV-2 activity: Change in proportion of specimens testing positive for SARS-CoV-2",
      "Specimens testing positive for influenza, by virus type and subtype",
    ];

    for (let i = 0; i < whoReport.images.length; i++) {
      const image = whoReport.images[i];
      const title = titles[i];

      try {
        // Calculate image dimensions to fit within page width while maintaining aspect ratio
        const imgWidth = maxWidth;
        const imgHeight = (imgWidth * image.height) / image.width;

        // Add new page if image won't fit
        if (
          currentY + imgHeight >
          doc.internal.pageSize.height - STYLES.MARGINS.BOTTOM
        ) {
          doc.addPage();
          currentY = STYLES.MARGINS.TOP;
        }

        // Add image
        doc.addImage(
          `data:image/${image.content_type};base64,${image.image_data}`,
          image.content_type.toUpperCase(),
          STYLES.MARGINS.LEFT,
          currentY,
          imgWidth,
          imgHeight
        );

        // Update Y position after adding the image
        currentY += imgHeight;

        // Add title below the image
        currentY += STYLES.LINE_HEIGHTS.HEADER;
        doc.setFont(STYLES.FONTS.HEADER);
        doc.setFontSize(STYLES.FONT_SIZES.SUBHEADER);
        doc.setTextColor(...STYLES.COLORS.PRIMARY);
        doc.text(title, STYLES.MARGINS.LEFT, currentY);
        currentY += STYLES.LINE_HEIGHTS.HEADER; // Add some space after the title
      } catch (error) {
        console.error("Error adding image to PDF:", error);
      }
    }
  }

  // Add source reference
  doc.setFontSize(STYLES.FONT_SIZES.FOOTER);
  doc.setTextColor(...STYLES.COLORS.SECONDARY);

  const sourceText = `Source: ${whoReport.report_url}`;
  const sourceLines = doc.splitTextToSize(sourceText, maxWidth);

  // Calculate the Y position for the source text
  const sourceY = doc.internal.pageSize.height - STYLES.MARGINS.BOTTOM;

  // Add each line of the source text
  for (let i = 0; i < sourceLines.length; i++) {
    doc.text(sourceLines[i], STYLES.MARGINS.LEFT, sourceY - 15 + i * 6); // Adjust for line height
  }
};
