/**
 * Adds France section to the PDF document with proper margin control
 * @param {Object} doc - PDF document instance
 * @param {Object} franceReport - France report data
 * @param {string} chartImage - Base64 encoded chart image
 * @returns {Promise<void>}
 */
export const addFranceSection = async (doc, franceReport, chartImage) => {
  const margin = 20;
  const pageWidth = doc.internal.pageSize.width;
  const contentWidth = pageWidth - margin * 2;

try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!franceReport) {
      console.warn("France report data is missing");
      return;
    }

    doc.addPage();

    // Title
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("France", margin, 30);

    // Report period
    const { requested_week_report } = franceReport.data;
    const weekId = requested_week_report.week_id;
    const year = weekId.slice(0, 4);
    const week = weekId.slice(4);
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(`Report for Year ${year}, Week ${week}`, margin, 45);

    // Summary Section
    doc.setFontSize(12);
    doc.setTextColor(41, 128, 185);
    doc.text("Summary of Acute Respiratory Infections (ARI)", margin, 65);

    // Render summary paragraphs as HTML
    const summaryParagraphs = requested_week_report.summary_paragraphs || [];
    let currentY = 80; // Starting Y position for summary text

    doc.setTextColor(0, 0, 0)

    summaryParagraphs.forEach((paragraph) => {
      // Remove <strong> tags and render as plain text
      const plainText = paragraph.replace(/<\/?strong>/g, "");
      const lines = doc.splitTextToSize(plainText, contentWidth);
      doc.text(lines, margin, currentY);
      currentY += lines.length * 7; // Adjust line height

      // Add a new page if the content exceeds the page height
      if (currentY > doc.internal.pageSize.height - 50) {
        doc.addPage();
        currentY = margin;
      }
    });

    // Add Chart Image
    if (chartImage) {
      const maxChartWidth = contentWidth * 0.8; // Reduce chart width to 80% of content width
      const maxChartHeight = 150; // Maximum height for the chart

      // Get the original dimensions of the chart image
      const img = new Image();
      img.src = chartImage;

      await new Promise((resolve) => {
        img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;
          const aspectRatio = originalWidth / originalHeight;

          // Calculate scaled dimensions to fit within maxChartWidth and maxChartHeight
          let chartWidth = maxChartWidth;
          let chartHeight = chartWidth / aspectRatio;

          if (chartHeight > maxChartHeight) {
            chartHeight = maxChartHeight;
            chartWidth = chartHeight * aspectRatio;
          }

          // Add the chart image to the PDF with scaled dimensions
          doc.addImage(
            chartImage,
            "PNG",
            margin + (contentWidth - chartWidth) / 2, // Center the chart horizontally
            currentY + 15,
            chartWidth,
            chartHeight
          );

          currentY += chartHeight + 20; // Adjust Y position after adding the chart
          resolve();
        };
      });
    }

    // Table Data Section
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Incidence Rates by Region", margin, currentY + 15);

    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);

    const tableData = requested_week_report.table_data || [];
    const startY = currentY + 25; // Starting Y position for the table
    const rowHeight = 10;
    const columnWidth = contentWidth / 3;

    // Table Header
    const header = ["Region", "Incidence Rate", "Incidence"];
    doc.setFillColor(200, 200, 200); // Light gray background for header
    doc.rect(margin, startY, contentWidth, rowHeight, "F");
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");

    header.forEach((text, index) => {
      doc.text(text, margin + index * columnWidth, startY + 7);
    });

    // Table Rows
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    let tableY = startY + rowHeight;
    tableData.forEach((row) => {
      doc.text(row.region, margin, tableY + 7);
      doc.text(row.incidence_rate, margin + columnWidth, tableY + 7);
      doc.text(row.incidence, margin + columnWidth * 2, tableY + 7);

      // Draw horizontal line between rows
      doc.setDrawColor(200, 200, 200);
      doc.line(
        margin,
        tableY + rowHeight,
        margin + contentWidth,
        tableY + rowHeight
      );
      tableY += rowHeight;

      // Add a new page if the table exceeds the page height
      if (tableY > doc.internal.pageSize.height - 50) {
        doc.addPage();
        tableY = margin;
      }
    });

    // Source citation
    doc.setFontSize(9);
    doc.setTextColor(128, 128, 128);
    doc.text(
      "Source: Sentiweb France (https://www.sentiweb.fr/)",
      margin,
      doc.internal.pageSize.height - 15
    );
  } catch (error) {
    console.error("Error generating France PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating France report section. Please try again later.",
      margin,
      40
    );
  }
};
