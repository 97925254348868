/**
 * Adds Brazil section to the PDF document with proper margin control
 * @param {Object} doc - PDF document instance
 * @param {Object} brazilReport - Brazil report data from Redux store
 * @param {Object} brazilRef - Reference to Brazil component for chart images
 * @returns {Promise<void>}
 */
export const addBrazilSection = async (doc, brazilReport, brazilRef) => {
  const margin = 20;
  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!brazilReport) {
      console.warn("Brazil report data is missing");
      return;
    }

    doc.addPage();

    const pageWidth = doc.internal.pageSize.width;
    const contentWidth = pageWidth - margin * 2;

    // Title
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("Brazil", margin, 30);

    // Report period
    const { current_report } = brazilReport.data;
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(
      `Report for Year ${current_report.iso_year || "N/A"}, Week ${
        current_report.iso_week || "N/A"
      }`,
      margin,
      45
    );

    // Surveillance Summary section
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Surveillance Summary", margin, 65);

    // Summary content with text wrapping
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);

    // Processing summary
    const processingText = `A total of ${
      current_report.flu_data.specimens.processed || 0
    } specimens have been processed through laboratory surveillance. The total number of specimens received stands at ${
      current_report.flu_data.specimens.received || 0
    }, with a processing rate of ${(
      (current_report.flu_data.specimens.processed /
        current_report.flu_data.specimens.received) *
      100
    ).toFixed(1)}%.`;
    const processingLines = doc.splitTextToSize(processingText, contentWidth);
    doc.text(processingLines, margin, 80);

    // Subtype Analysis section
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Subtype Analysis", margin, 110);

    // Create a table-like structure for subtype analysis
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0);

    // Subtype details
    const subtypeDetails = [
      {
        type: "AH1N1 2009:",
        value: `${
          current_report.flu_data.influenza_a.h1n1_2009 || 0
        } cases reported`,
      },
      {
        type: "AH3:",
        value: `${current_report.flu_data.influenza_a.h3 || 0} cases reported`,
      },
      {
        type: "AH5:",
        value: current_report.flu_data.influenza_a.h5
          ? "Cases reported"
          : "No cases reported during this period",
      },
      {
        type: "Victoria lineage:",
        value: `${
          current_report.flu_data.influenza_b.victoria || 0
        } cases reported`,
      },
      {
        type: "Yamagata lineage:",
        value: current_report.flu_data.influenza_b.yamagata
          ? "Cases reported"
          : "No cases reported during this period",
      },
    ];

    let yPosition = 125;
    const labelWidth = 40;
    const lineHeight = 12;

    subtypeDetails.forEach((subtype) => {
      // Type label (left column)
      doc.setFont(undefined, "bold");
      doc.text(subtype.type, margin, yPosition);

      // Value (right column with wrapping)
      doc.setFont(undefined, "normal");
      const valueLines = doc.splitTextToSize(subtype.value, contentWidth - 80);
      doc.text(valueLines, margin + 80, yPosition);

      yPosition += lineHeight * Math.max(valueLines.length, 1);
    });

    // Source citation
    doc.setFontSize(9);
    doc.setTextColor(128, 128, 128);
    doc.text("Source: WHO FluNet", margin, doc.internal.pageSize.height - 15);

    // Add charts if available
    if (brazilRef?.current) {
      try {
        const chartImages = await brazilRef.current.getChartImages();
        if (chartImages) {
          doc.addPage();
          doc.setFontSize(16);
          doc.setTextColor(0, 51, 102);
          doc.text("Surveillance Data Visualization", margin, 30);

          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          const imageWidth = pageWidth - margin * 2;
          const imageHeight = imageWidth * 0.5; // Maintain aspect ratio

          let yPosition = 50;

          for (const [name, imageData] of Object.entries(chartImages)) {
            if (imageData) {
              // Check if we need a new page
              if (yPosition + imageHeight > pageHeight - margin) {
                doc.addPage();
                yPosition = margin;
              }

              doc.addImage(
                imageData,
                "JPEG",
                margin,
                yPosition,
                imageWidth,
                imageHeight,
                undefined,
                "MEDIUM"
              );

              yPosition += imageHeight + 20;
            }
          }
        }
      } catch (error) {
        console.error("Error adding charts to PDF:", error);
      }
    }
  } catch (error) {
    console.error("Error generating Brazil PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating Brazil report section. Please try again later.",
      margin,
      40
    );
  }
};
