const fetchImageAsBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
};

const formatStatistics = (statistics) => {
  if (!statistics) return null;
  return {
    morbidity: {
      rate: statistics.morbidity_rate || 0,
      totalSamples: statistics.total_samples || 0,
    },
    positiveCases: {
      influenzaA: statistics.positive_cases?.influenza_a || 0,
      influenzaB: statistics.positive_cases?.influenza_b || 0,
      otherArvi: statistics.positive_cases?.other_arvi || 0,
    },
    covidStats: {
      totalCases: statistics.covid_stats?.total_cases || 0,
      totalDeaths: statistics.covid_stats?.total_deaths || 0,
      weeklyCases: statistics.covid_stats?.weekly_cases || 0,
      weeklyDeaths: statistics.covid_stats?.weekly_deaths || 0,
    },
  };
};

const addChartsToDocument = async (doc, charts) => {
  if (!charts || charts.length === 0) return;

  // Start new page for charts
  doc.addPage();
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.text("Surveillance Charts", 20, 30);

  let currentY = 50;
  const pageWidth = doc.internal.pageSize.width;
  const margin = 20;
  const imageWidth = (pageWidth - 3 * margin) / 2;
  const imageHeight = 100;

  for (let i = 0; i < charts.length; i++) {
    const chart = charts[i];

    // Handle either direct image data or URL
    let imageData = chart.image;
    if (typeof imageData === "string" && imageData.startsWith("http")) {
      imageData = await fetchImageAsBase64(imageData);
    }

    if (!imageData) continue;

    // Check if we need a new page
    if (currentY + imageHeight + 30 > doc.internal.pageSize.height) {
      doc.addPage();
      currentY = 30;
    }

    // Calculate x position (alternate between left and right)
    const xPosition = i % 2 === 0 ? margin : margin * 2 + imageWidth;

    try {
      // Add image using base64 data
      doc.addImage(
        imageData,
        "JPEG",
        xPosition,
        currentY,
        imageWidth,
        imageHeight,
        undefined,
        "FAST"
      );

      // Add title below image
      doc.setFontSize(10);
      const titleLines = doc.splitTextToSize(chart.title, imageWidth);
      doc.text(titleLines, xPosition, currentY + imageHeight + 10);

      // Move to next row if we've placed two images
      if (i % 2 === 1) {
        currentY += imageHeight + 30 + titleLines.length * 5;
      }
    } catch (error) {
      console.error(`Error adding chart to PDF:`, error);
      continue;
    }
  }
};

export const addRussiaSection = async (doc, russiaReport, russiaRef) => {
  const margin = 20;
  const lineHeight = 7;
  const sectionSpacing = 15;

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!russiaReport) {
      console.warn("Russia report data is missing");
      return;
    }

    // Add new page for Russia section
    doc.addPage();

    doc.setFont("helvetica", "normal");

    // Add header
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("Russia", margin, 40);

    // Add report metadata
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(
      [
        `Year: ${russiaReport.year || "N/A"}`,
        `Week: ${russiaReport.week || "N/A"}`,
      ],
      margin,
      60
    );

    // Add highlights
    doc.setFontSize(14);
    doc.setTextColor(41, 128, 185);
    doc.text("Key Highlights", margin, 80);

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    let yPos = 90;

    russiaReport.highlights?.forEach((highlight) => {
      const lines = doc.splitTextToSize(
        highlight,
        doc.internal.pageSize.width - margin * 2
      );
      doc.text(lines, margin, yPos);
      yPos += lines.length * lineHeight + sectionSpacing;
    });

    // Add statistics
    const stats = formatStatistics(russiaReport.statistics);
    if (stats) {
      doc.setFontSize(14);
      doc.setTextColor(0, 51, 102);
      doc.text("Statistical Overview", margin, yPos + 10);

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text(
        [
          `Total Samples: ${stats.morbidity.totalSamples}`,
          "",
          "Positive Cases:",
          `  Influenza A: ${stats.positiveCases.influenzaA}`,
          `  Influenza B: ${stats.positiveCases.influenzaB}`,
          `  Other ARVI: ${stats.positiveCases.otherArvi}%`,
          "",
          "COVID-19 Statistics:",
          `  Total Cases: ${stats.covidStats.totalCases.toLocaleString()}`,
          `  Total Deaths: ${stats.covidStats.totalDeaths.toLocaleString()}`,
          `  Weekly Cases: ${stats.covidStats.weeklyCases.toLocaleString()}`,
          `  Weekly Deaths: ${stats.covidStats.weeklyDeaths.toLocaleString()}`,
        ],
        margin,
        yPos + 30
      );
    }

    // Get and process charts using the ref
    const chartPromises = await russiaRef.current?.getChartImages();
    if (chartPromises && chartPromises.length > 0) {
      const charts = await Promise.all(chartPromises);
      await addChartsToDocument(doc, charts);
    }

    // Add source attribution
    doc.setFontSize(10);
    doc.setTextColor(128, 128, 128);
    doc.text(
      "Source: Research Institute of Influenza, Saint Petersburg, Russia",
      margin,
      doc.internal.pageSize.height - 20
    );
  } catch (error) {
    console.error("Error generating Russia PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating Russia report section. Please try again later.",
      margin,
      40
    );
  }
};
