import React, { useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";

import { Loader } from "../UI/Loader/Loader";
import { fetchChinaReport } from "../../redux/slices/china.slice";
import { renderGoToSourceButton } from "./Italy";

const China = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error } = useSelector((state) => state.china);

  useEffect(() => {
    if (!report) {
      dispatch(fetchChinaReport());
    }
  }, [dispatch, report]);

  const renderReportWeekYear = () => {
    const year = report?.year;
    const week = report?.week_number;

    return (
      <div className="alert alert-info mb-4">
        <i className="fa fa-info-circle me-2"></i>
        Report for Year {year}, Week {week}
      </div>
    );
  };

  const renderSummarySection = (title, data) => {
    if (!data) return null;
    return (
      <div className="mb-4">
        <h6 className="text-primary mb-3">{title}</h6>
        <div className="card bg-light">
          <div className="card-body">
            {data.raw_text && (
              <p className="mb-2 text-secondary">{data.raw_text}</p>
            )}
            {data.status === "parsed" && (
              <div className="mt-3">
                {data.regions_with_increase && (
                  <div className="d-flex align-items-center mb-2">
                    <i className="fas fa-chart-line text-warning me-2"></i>
                    <span>
                      Increased in: {data.regions_with_increase.join(", ")}
                    </span>
                  </div>
                )}
                {data.predominant_strain && (
                  <div className="d-flex align-items-center mb-2">
                    <i className="fas fa-virus text-info me-2"></i>
                    <span>Predominant strain: {data.predominant_strain}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderImages = (images) => {
    if (!images || images.length === 0) return null;
    return (
      <div className="mt-4">
        <h6 className="text-primary mb-3">Report Visualizations</h6>
        <div className="row">
          {images.map((image, index) => (
            <div key={index} className="col-md-6 mb-3">
              <div className="card">
                <img
                  src={`data:image/${image.content_type};base64,${image.image_data}`}
                  alt={`Report visualization ${index + 1}`}
                  className="img-fluid"
                />
                <div className="card-footer text-muted">
                  Page {image.page_number}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="alert alert-danger">
        <i className="fas fa-exclamation-circle me-2"></i>
        {error}
      </div>
    );
  if (!report)
    return (
      <div className="alert alert-info">
        <i className="fas fa-info-circle me-2"></i>
        No report available.
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="country-name mb-4">China</h2>
        {/* <IconButton
                     Icon={LuRefreshCcw}
                     onClick={handleRefresh}
                     className="btn btn-outline-primary"
                   /> */}
        {renderGoToSourceButton(report?.report_url)}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card shadow-sm">
            <div className="card-body">
              {renderReportWeekYear()}
              <div className="row">
                <div className="col-md-8">
                  {renderSummarySection(
                    "Outbreak Information",
                    report.summary.detection_info
                  )}
                  {renderSummarySection(
                    "Characterization Information",
                    report.summary.characterization_info
                  )}
                  {renderSummarySection(
                    "Resistance Information",
                    report.summary.resistance_info
                  )}
                </div>
                <div className="col-md-4">
                  <div className="card bg-light">
                    <div className="card-body">
                      <h6 className="text-primary mb-3">Report Details</h6>
                      <div className="d-flex align-items-center mb-2">
                        <i className="fa fa-calendar me-2"></i>
                        <span>
                          Report Date:{" "}
                          {format(new Date(report.report_date), "PPP")}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <i className="fa fa-link me-2"></i>
                        <a
                          href={report.pdf_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View PDF Report
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {renderImages(report.images)}

              <div className="mt-4 p-4">
                <p className="d-flex align-items-center text-muted">
                  <i className="fa fa-link me-2"></i>
                  <span>
                    Source:{" "}
                    <a href={report?.report_url}>https://ivdc.chinacdc.cn</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

China.displayName = "China";
export default China;
