// // src/components/pages/Whoo.jsx
// import React, {
//   useEffect,
//   useMemo,
//   useImperativeHandle,
//   useRef,
//   forwardRef,
// } from "react";
// import PropTypes from "prop-types";
// import "./styles/Whoo.css";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchData, refreshData } from "../../redux/slices/who.slice";
// import { getCurrentWeekNumber } from "../../utils/cfunctions";
// import EChartsWrapper from "../EChartsWrapper";

// import { LuRefreshCcw } from "react-icons/lu";
// import { IconButton } from "../UI/IconButton";
// import { Loader } from "../UI/Loader/Loader";
// import { MapChartWrapper } from "../MapGraph/MapChartWrapper";
// import { AgMapChartWrapper } from "../AgMapChart/AgMapChartWrapperWHO";

import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, refreshData } from "../../redux/slices/who.slice";
import { getCurrentWeekNumber } from "../../utils/cfunctions";
import { LuRefreshCcw } from "react-icons/lu";
import { IconButton } from "../UI/IconButton";
import { Loader } from "../UI/Loader/Loader";
import { renderGoToSourceButton } from "./Italy";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("Error Boundary Caught an error:", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error">
          Something went wrong: {this.state.error.message}
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

// const Whoo = forwardRef((props, ref) => {
//   const dispatch = useDispatch();

//   const influenzaAgMapChartRef = useRef(null);
//   const iliAgMapChartRef = useRef(null);
//   const sariAgMapChartRef = useRef(null);

//   const {
//     influenzaData,
//     iliData,
//     sariData,
//     report,
//     loading,
//     error,
//     dataLoaded,
//     lastFetched,
//   } = useSelector((state) => state.who);

//   const memoizedInfluenzaData = useMemo(() => influenzaData, [influenzaData]);
//   const memoizedIliData = useMemo(() => iliData, [iliData]);
//   const memoizedSariData = useMemo(() => sariData, [sariData]);

//   useEffect(() => {
//     const FRESHNESS_THRESHOLD = 24 * 60 * 60 * 1000;
//     const now = Date.now();

//     if (
//       !dataLoaded ||
//       !lastFetched ||
//       now - lastFetched > FRESHNESS_THRESHOLD
//     ) {
//       dispatch(fetchData());
//     }
//   }, [dispatch, dataLoaded, lastFetched]);

//   const handleRefresh = () => {
//     dispatch(refreshData());
//     dispatch(fetchData());
//   };

//   const renderWhoReport = () => {
//     if (!report) return <div>No report data available</div>;

//     return (
//       <div>
//         <h6 className="mt-4 mb-3 report-header">{report.title}</h6>

//         <div className="report-item">
//           <i className="fa fa-globe icon"></i>
//           <span>{report.global_coverage}</span>
//         </div>

//         <div className="report-item">
//           <i className="fa fa-vials icon"></i>
//           <span>{report.specimen_summary}</span>
//         </div>

//         <h6 className="mt-4 mb-3 report-header">Subtype Analysis</h6>
//         {report?.subtype_analysis?.map((summary, index) => (
//           <div className="report-item" key={index}>
//             <i className="fa fa-check-circle icon"></i>
//             <span>{summary}</span>
//           </div>
//         ))}

//         <div className="report-item">
//           <i className="fa fa-heartbeat icon"></i>
//           <span>{report.sari_surveillance}</span>
//         </div>

//         <div className="report-item">
//           <i className="fa fa-virus icon"></i>
//           <span>{report.ili_surveillance}</span>
//         </div>

//         <h6 className="mt-4 mb-3 report-header">Total Burden</h6>
//         <div className="report-item">
//           <i className="fa fa-syringe icon"></i>
//           <span>{report.total_burden}</span>
//         </div>
//       </div>
//     );
//   };

//   useImperativeHandle(ref, () => ({
//     getChartImages: async () => {
//       const influenzaAgMapChartImage = influenzaAgMapChartRef.current
//         ? await influenzaAgMapChartRef.current.getAgMapChartImage()
//         : null;
//       const iliMapChartImage = iliAgMapChartRef.current
//         ? await iliAgMapChartRef.current.getAgMapChartImage()
//         : null;
//       const sariMapChartImage = sariAgMapChartRef.current
//         ? await sariAgMapChartRef.current.getAgMapChartImage()
//         : null;

//       return {
//         influenzaAgMapChart: influenzaAgMapChartImage,
//         iliAgMapChart: iliMapChartImage,
//         sariAgMapChart: sariMapChartImage,
//       };
//     },
//   }));

//   if (loading && !dataLoaded) return <Loader />;
//   if (error) return <div className="error">Error: {error}</div>;

//   return (
//     <ErrorBoundary>
//       <div className="d-flex justify-content-between">
//         <h2 className="country-name">WHO</h2>
//         <IconButton Icon={LuRefreshCcw} onClick={handleRefresh} />
//       </div>
//       <div className="card">
//         <div className="card-body">
//           <div className="alert alert-info mb-4">
//             <i className="fa fa-info-circle me-2"></i>
//             Report for Year {new Date().getFullYear()}, Week{" "}
//             {getCurrentWeekNumber()}
//           </div>
//           <div className="card-body d-flex flex-row justify-content-between">
//             <div className="content" style={{ width: "55%" }}>
//               {renderWhoReport()}
//             </div>

//             <div
//               className="charts-container"
//               style={{ width: "45%", display: "flex", flexDirection: "column" }}
//             >
//               <AgMapChartWrapper
//                 ref={influenzaAgMapChartRef}
//                 dataType="Specimens"
//                 title="Influenza Specimens"
//                 colorName="Number of specimens"
//                 color="#8884d8"
//                 data={memoizedInfluenzaData}
//               />
//               <AgMapChartWrapper
//                 ref={iliAgMapChartRef}
//                 dataType="ILI"
//                 title="Influenza-like Illness (ILI)"
//                 colorName="ILI percentage"
//                 // unit="%"
//                 color="#82ca9d"
//                 data={memoizedIliData}
//               />
//               <AgMapChartWrapper
//                 ref={sariAgMapChartRef}
//                 dataType="SARI"
//                 title="Severe Acute Respiratory Infection (SARI)"
//                 colorName="SARI percentage"
//                 // unit="%"
//                 color="#ffc658"
//                 data={memoizedSariData}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="mt-4 p-4">
//           <p className="d-flex align-items-center text-muted">
//             <i className="fa fa-link me-2"></i>
//             <span>
//               Source:{" "}
//               <a href="https://www.who.int/publications/m/item/influenza-update-n--507">
//                 https://www.who.int
//               </a>
//             </span>
//           </p>
//         </div>
//       </div>
//     </ErrorBoundary>
//   );
// });

// export default Whoo;

const WHO = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading, error, dataLoaded, lastFetched } = useSelector(
    (state) => state.who
  );

  useEffect(() => {
    const FRESHNESS_THRESHOLD = 24 * 60 * 60 * 1000; // 24 hours
    const now = Date.now();

    if (
      !dataLoaded ||
      !lastFetched ||
      now - lastFetched > FRESHNESS_THRESHOLD
    ) {
      dispatch(fetchData());
    }
  }, [dispatch, dataLoaded, lastFetched]);

  const handleRefresh = () => {
    dispatch(refreshData());
    dispatch(fetchData());
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const renderSummarySection = (title, content) => {
    if (!content) return null;

    return (
      <div className="mb-4">
        <h5 className="text-primary mb-3">{title}</h5>
        <div className="card bg-light">
          <div className="card-body">
            {content.split("\n").map(
              (line, index) =>
                line.trim() && (
                  <p key={index} className="mb-2">
                    {line.trim()}
                  </p>
                )
            )}
          </div>
        </div>
      </div>
    );
  };

  useImperativeHandle(ref, () => ({
    getImages: () => report?.images || [],
  }));

  const renderReportWeekYear = () => {
    const year = report?.year;
    const week = report?.week_number;

    return (
      <div className="alert alert-info mb-4">
        <i className="fa fa-info-circle me-2"></i>
        Report for Year {year}, Week {week}
      </div>
    );
  };

  const renderImages = (images) => {
    if (!images || images.length === 0) return null;
    const titles = [
      "Influenza activity: Proportion of specimens testing positive for influenza",
      "SARS-CoV-2 activity: Proportion of specimens testing positive for SARS-CoV-2",
      "Influenza activity: Change in proportion of specimens testing positive for influenza ",
      "SARS-CoV-2 activity: Change in proportion of specimens testing positive for SARS-CoV-2",
      "Specimens testing positive for influenza, by virus type and subtype",
    ];

    return (
      <div className="mt-4">
        <h6 className="text-primary mb-3">Report Visualizations</h6>
        <div className="column">
          {images.map((image, index) => (
            <div key={index} className="mb-3 w-full">
              <div>
                <img
                  src={`data:image/${image.content_type};base64,${image.image_data}`}
                  alt={`Report visualization ${index + 1}`}
                  className="img-fluid"
                />
                <div className="d-flex align-items-center justify-content-center my-2 py-2">
                  <h5>{titles[index] && titles[index]}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (loading && !dataLoaded) {
    return (
      <div className="d-flex justify-content-center p-5">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger">
        <h4 className="alert-heading">Error Loading Data</h4>
        <p>{error}</p>
      </div>
    );
  }

  if (!report) {
    return (
      <div className="alert alert-warning">
        <h4 className="alert-heading">No Data Available</h4>
        <p>Unable to load the WHO report data.</p>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2 className="country-name mb-4">WHO</h2>
          {/* <IconButton
            Icon={LuRefreshCcw}
            onClick={handleRefresh}
            className="btn btn-outline-primary"
          /> */}
          {renderGoToSourceButton(
            "https://www.who.int/teams/global-influenza-programme/surveillance-and-monitoring/influenza-updates/current-influenza-update"
          )}
        </div>

        <div className="card">
          <div className="card-body">
            {renderReportWeekYear()}
            <div className="row">
              <div className="col-12">
                {renderSummarySection(
                  "Influenza Summary",
                  report.summary?.influenza_info?.northern_hemisphere +
                    "\n" +
                    report.summary?.influenza_info?.southern_hemisphere
                )}

                {renderSummarySection(
                  "SARS-CoV-2 Summary",
                  report.summary?.sars_cov2_info?.raw_text
                )}

                {report.summary?.additional_info_text && (
                  <div className="mt-4">
                    <h5 className="text-primary mb-3">
                      Additional Information
                    </h5>
                    <div className="card bg-light">
                      <div className="card-body">
                        {report.summary.additional_info_text}
                      </div>
                    </div>
                  </div>
                )}

                {report?.images && renderImages(report.images)}
              </div>
            </div>
          </div>

          <div className="mt-4 p-4">
            <p className="d-flex align-items-center text-muted">
              <i className="fa fa-link me-2"></i>
              <span>
                Source: <a href={report?.report_url}>https://www.who.int</a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});

WHO.displayName = "WHO";

export default WHO;
