import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { ErrorBoundary } from "./Whoo";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../UI/Loader/Loader";
import { fetchFranceReport } from "../../redux/slices/france.slice";
import EChartsWrapper from "../EChartsWrapper";
import { Table } from "../Table/Table";
import { renderGoToSourceButton } from "./Italy";

const France = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { report, loading } = useSelector((state) => state.france);
  const [sortedWeeklyData, setSortedWeeklyData] = useState(
    [...(report?.data?.weekly_data_available || [])].sort(
      (a, b) => a.week - b.week
    )
  );

  const influenzaAgMapChartRef = useRef(null);

  useEffect(() => {
    if (!report) {
      dispatch(fetchFranceReport());
    }
    setSortedWeeklyData(
      [...(report?.data?.weekly_data_available || [])].sort(
        (a, b) => a.week - b.week
      )
    );
  }, [dispatch, report]);

  useImperativeHandle(ref, () => ({
    getChartImages: async () => {
      try {
        const influenzaAgMapChartImage = influenzaAgMapChartRef.current
          ? await influenzaAgMapChartRef.current.getChartImage()
          : null;

        return {
          influenzaAgMapChart: influenzaAgMapChartImage,
        };
      } catch (error) {
        console.error("Error getting chart images:", error);
        return null;
      }
    },
  }));

  if (loading) return <Loader />;
  if (!report) return <p className="info-message">No report available.</p>;

  const renderReportWeekYear = () => {
    const weekId = report.data.requested_week_report?.week_id;
    const year = parseInt(weekId.slice(0, 4), 10);
    const week = parseInt(weekId.slice(4), 10);

    return (
      <div className="alert alert-info mb-4">
        <i className="fa fa-info-circle me-2"></i>
        Report for Year {year}, Week {week}
      </div>
    );
  };

  const renderRequestedWeekReport = () => {
    const requestedReport = report.data.requested_week_report;
    const summaryParagraphs = requestedReport?.summary_paragraphs || [];

    return (
      <div>
        {summaryParagraphs.map((paragraph, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      </div>
    );
  };

  const tableColumns = [
    { label: "Region", accessor: "region" },
    { label: "Incidence", accessor: "incidence" },
    { label: "Incidence rate", accessor: "incidence_rate" },
  ];

  const tableData = report?.data?.requested_week_report?.table_data;

  const customStyles = {
    headerBgColor: "#4CAF50",
    headerTextColor: "#FFFFFF",
    rowBgColor: "#FAFAFA",
    rowTextColor: "#333333",
    alternateRowColor: "#E8F5E9",
  };
  const sourceUrl = "https://www.sentiweb.fr/?lang=en";

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="country-name mb-4">France</h2>
        {/* <IconButton
                      Icon={LuRefreshCcw}
                      onClick={handleRefresh}
                      className="btn btn-outline-primary"
                    /> */}
        {renderGoToSourceButton(sourceUrl)}
      </div>
      <div className="card">
        <div className="card-body">
          {renderReportWeekYear()}
          <ErrorBoundary>
            <div className="card-body d-flex">
              <div className="content" style={{ width: "55%" }}>
                {renderRequestedWeekReport()}
              </div>
              <div
                className="charts-container"
                style={{
                  width: "45%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <EChartsWrapper
                  ref={influenzaAgMapChartRef}
                  color="#3187ae"
                  title={"Acute Respiratory Infection (ARI)"}
                  data={sortedWeeklyData?.map((data) => {
                    return {
                      value: data.inc,
                      date_value: `${String(data.week).slice(
                        0,
                        4
                      )}, week ${String(data.week).slice(4)}`,
                    };
                  })}
                />
              </div>
            </div>
            <div className="content" style={{ width: "90%", margin: "auto" }}>
              <Table
                columns={tableColumns}
                data={tableData}
                customStyles={customStyles}
              />
            </div>
          </ErrorBoundary>
        </div>
        <div className="mt-4 p-4">
          <p className="d-flex align-items-center text-muted">
            <i className="fa fa-link me-2"></i>
            <span>
              Source: <a href={sourceUrl}>https://www.sentiweb.fr</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
});

France.displayName = "France";
export default France;
