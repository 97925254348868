/**
 * Adds the China section to the PDF document.
 * @param {Object} doc - PDF document instance
 * @param {Object} chinaReport - China report data
 * @returns {Promise<void>}
 */
export const addChinaSection = async (doc, chinaReport) => {
  const margin = 20;
  const pageHeight = doc.internal.pageSize.height;
  const contentWidth = doc.internal.pageSize.width - margin * 2;

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!chinaReport) {
      console.warn("China report data is missing");
      return;
    }

    const { week_number, year, summary, report_url } = chinaReport;

    // Start with a new page
    doc.addPage();

    // Title Section
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("China", margin, 30);

    // Metadata
    doc.setFontSize(14);
    doc.text(`Report for Week ${week_number}, ${year}`, margin, 45);
    doc.setFontSize(10);
    doc.setTextColor(0, 102, 204);
    doc.textWithLink("View Full Report Online", margin, 55, {
      url: report_url,
    });

    let currentY = 70;

    // Detection Information
    if (summary.detection_info) {
      doc.setFontSize(16);
      doc.setTextColor(41, 128, 185);
      doc.text("Detection Information", margin, currentY);
      currentY += 10;

      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0);
      const detectionText = summary.detection_info.raw_text;
      const detectionLines = doc.splitTextToSize(detectionText, contentWidth);

      detectionLines.forEach((line) => {
        if (currentY > pageHeight - margin) {
          doc.addPage();
          currentY = margin; // Reset position
        }
        doc.text(line, margin, currentY);
        currentY += 8;
      });
    }

    // Outbreak Information
    if (summary.outbreak_info) {
      if (currentY > pageHeight - margin) {
        doc.addPage();
        currentY = margin;
      }

      doc.setFontSize(16);
      doc.setTextColor(41, 128, 185);
      doc.text("Outbreak Information", margin, currentY);
      currentY += 10;

      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0);
      const outbreakText = `Number of outbreaks reported: ${summary.outbreak_info.count}`;
      doc.text(outbreakText, margin, currentY);
      currentY += 8;
    }

    // Characterization Information
    if (summary.characterization_info) {
      if (currentY > pageHeight - margin) {
        doc.addPage();
        currentY = margin;
      }

      doc.setFontSize(16);
      doc.setTextColor(41, 128, 185);
      doc.text("Characterization Information", margin, currentY);
      currentY += 10;

      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0);
      const characterizationText = summary.characterization_info.raw_text;
      const characterizationLines = doc.splitTextToSize(
        characterizationText,
        contentWidth
      );

      characterizationLines.forEach((line) => {
        if (currentY > pageHeight - margin) {
          doc.addPage();
          currentY = margin; // Reset position
        }
        doc.text(line, margin, currentY);
        currentY += 8;
      });
    }

    // Resistance Information
    if (summary.resistance_info) {
      if (currentY > pageHeight - margin) {
        doc.addPage();
        currentY = margin;
      }

      doc.setFontSize(16);
      doc.setTextColor(41, 128, 185);
      doc.text("Resistance Information", margin, currentY);
      currentY += 10;

      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0);
      const resistanceText = summary.resistance_info.raw_text;
      const resistanceLines = doc.splitTextToSize(resistanceText, contentWidth);

      resistanceLines.forEach((line) => {
        if (currentY > pageHeight - margin) {
          doc.addPage();
          currentY = margin; // Reset position
        }
        doc.text(line, margin, currentY);
        currentY += 8;
      });
    }

    // Add Images
    if (chinaReport.images && chinaReport.images.length > 0) {
      for (const image of chinaReport.images) {
        if (currentY > pageHeight - margin - image.height / 4) {
          doc.addPage();
          currentY = margin;
        }
        const imageData = `data:image/png;base64,${image.image_data}`;
        const imgWidth = contentWidth;
        const imgHeight = (image.height / image.width) * imgWidth;
        doc.addImage(imageData, "PNG", margin, currentY, imgWidth, imgHeight);
        currentY += imgHeight + 10;
      }
    }

    // Source Citation
    if (currentY > pageHeight - margin) {
      doc.addPage();
      currentY = margin;
    }
    doc.setFontSize(9);
    doc.setTextColor(128, 128, 128);
    doc.text("Source: China CDC", margin, pageHeight - 15);
  } catch (error) {
    console.error("Error generating China PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating China report section. Please try again later.",
      margin,
      40
    );
  }
};
