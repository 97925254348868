import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchItalyReport = createAsyncThunk(
  "italy/fetchItalyReport",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/get-italy-ari-reports`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Italy report");
      }
      const data = await response.json();
      return {
        data: {
          ...data,
          created_at: data.created_at || new Date().toISOString(),
          images: data.images || [],
        },
      };
    } catch (err) {
      return rejectWithValue(err.message || "Failed to fetch report");
    }
  }
);

const initialState = {
  report: null,
  graph_data: null,
  loading: true,
  error: null,
};

const italySlice = createSlice({
  name: "italy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItalyReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchItalyReport.fulfilled, (state, action) => {
        state.report = action.payload?.data?.data;
        state.graph_data = action.payload?.data?.graph_data;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchItalyReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.report = null;
      });
  },
});

export default italySlice.reducer;
