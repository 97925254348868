/**
 * Adds Spain section to the PDF document with proper margin control and formatting
 * @param {Object} doc - PDF document instance
 * @param {Object} reportSpain - Spain report data
 * @param {Object} spainRef - Reference to Spain component
 * @returns {Promise<void>}
 */
export const addSpainSection = async (doc, reportSpain, spainRef) => {
  // Define margins and content width
  const margin = 20;
  const lineHeight = 7;
  const sectionSpacing = 15;

  try {
    if (!doc) throw new Error("PDF document instance is required");
    if (!reportSpain || reportSpain.status !== "success") {
      console.warn("Spain report data is missing or invalid");
      return;
    }

    // Add a new page for the Spain section
    doc.addPage();

    const pageWidth = doc.internal.pageSize.width;
    const contentWidth = pageWidth - margin * 2;

    // Title
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("Spain", margin, 30);

    // Report timestamp
    const reportTimestamp = new Date(reportSpain.timestamp).toLocaleDateString(
      "en-GB",
      {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
    );
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(`Report generated on: ${reportTimestamp}`, margin, 50);

    // Summary section
    doc.setFontSize(16);
    doc.setTextColor(41, 128, 185);
    doc.text("Summary", margin, 70);

    // Process summary data
    const summaryData = reportSpain.data.summary;
    if (summaryData && summaryData.length > 0) {
      let yPosition = 85;
      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0);

      summaryData.forEach((section) => {
        // Add section paragraphs
        doc.setFont(undefined, "normal");
        section.paragraphs.forEach((paragraph) => {
          const lines = doc.splitTextToSize(paragraph, contentWidth);
          const textHeight = lines.length * lineHeight;

          // Check if there's enough space on the current page
          if (yPosition + textHeight > doc.internal.pageSize.height - margin) {
            doc.addPage(); // Add a new page
            yPosition = margin + 20; // Reset Y position for the new page
          }

          doc.text(lines, margin, yPosition);
          yPosition += textHeight; // Adjust line height
        });

        yPosition += sectionSpacing; // Add extra space between sections
      });
    } else {
      doc.setFontSize(11);
      doc.setTextColor(0, 0, 0);
      doc.text("No summary data available.", margin, 85);
    }

    // Graphs section
    if (reportSpain.data.graphs) {
      doc.addPage(); // Start a new page for graphs

      let chartY = margin + 20; // Initial Y position for the first chart
      const chartWidth = contentWidth * 0.8; // Use 80% of content width for better visibility
      const chartX = (pageWidth - chartWidth) / 2; // Center the chart horizontally

      for (const [name, imageData] of Object.entries(reportSpain.data.graphs)) {
        if (imageData) {
          try {
            // Decode base64 image and get its dimensions
            const img = new Image();
            img.src = `data:image/png;base64,${imageData}`;

            await new Promise((resolve) => {
              img.onload = () => {
                // Calculate height to maintain aspect ratio
                const aspectRatio = img.width / img.height;
                const chartHeight = chartWidth / aspectRatio;

                // Check if there's enough space on the current page
                if (chartY + chartHeight > doc.internal.pageSize.height - margin) {
                  doc.addPage();
                  chartY = margin + 20; // Reset Y position for the new page
                }

                // Add chart image to PDF
                doc.addImage(
                  imageData,
                  "PNG",
                  chartX,
                  chartY,
                  chartWidth,
                  chartHeight
                );

                chartY += chartHeight + 30; // Add spacing between charts

                resolve();
              };
            });
          } catch (error) {
            console.error(`Error adding chart ${name} to PDF:`, error);
          }
        }
      }
    }

    // Source citation
    doc.setFontSize(9);
    doc.setTextColor(128, 128, 128);
    doc.text("Source: WHO FluNet", margin, doc.internal.pageSize.height - 15);
  } catch (error) {
    console.error("Error generating Spain PDF section:", error);
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating Spain report section. Please try again later.",
      margin,
      40
    );
  }
};