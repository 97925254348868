import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  report: null,
  loading: true,
  error: null,
};

export const fetchFranceReport = createAsyncThunk(
  "france/fetchFranceReport",
  async (week_id = null, { rejectWithValue }) => {
    try {
      const weekIdToFetch = null;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/get-france-ari-reports`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(weekIdToFetch),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch France report");
      }

      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  }
);

const franceSlice = createSlice({
  name: "france",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFranceReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFranceReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
      })
      .addCase(fetchFranceReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default franceSlice.reducer;
