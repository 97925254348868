import React, { useState } from 'react';
import CommonLoginRegisterComp from './CommonLoginRegisterComp';
import { useNavigate } from 'react-router-dom';

function Resetpassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/forget-password`, { // Change URL if needed
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      navigate('/');

    } catch (error) {
      console.error('Error:', error);
      setError('Failed to reset password. Please check your email and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommonLoginRegisterComp
      title1="Reset "
      title2=" your Password"
      isRegister={false}
      isReserpassword={true}
      errorMessage={error}
      buttonText={loading ? 'Processing...' : 'Continue'}
      linkText2="Back to Sign In"
      linkUrl="/"
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      email={email}
      checkboxText="Remember me"
    />
  );
}

export default Resetpassword;
