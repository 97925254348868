import { jsPDF } from "jspdf";
import { toast } from "react-hot-toast";

import { addWhoSection } from "./pdf_sections/who_section";
import { addRussiaSection } from "./pdf_sections/russia_section";
import { addItalySection } from "./pdf_sections/italy_section";
import { addIndonesiaSection } from "./pdf_sections/indonesia_section";
import { addBrazilSection } from "./pdf_sections/brazil_section";
import { addFranceSection } from "./pdf_sections/france_section";
import { addChinaSection } from "./pdf_sections/china_section";
import { addSpainSection } from "./pdf_sections/spain_section";

export const getCurrentWeekNumber = () => {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const pastDaysOfYear = (now - startOfYear) / 86400000;
  return Math.floor((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
};

export const formatDate = (dateString) => {
  try {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Date not available";
  }
};

export const generateComprehensivePDF = async (
  whoRef,
  russiaRef,
  italyRef,
  spainRef,
  chinaRef,
  indonesiaRef,
  brazilRef,
  franceRef,
  reportWHO,
  reportRussia,
  reportItaly,
  reportSpain,
  reportChina,
  reportIndonesia,
  reportBrazil,
  reportFrance,
  setLoading
) => {
  if (
    !reportWHO ||
    !reportRussia ||
    !reportItaly ||
    !reportSpain ||
    !reportChina ||
    !reportIndonesia ||
    !reportBrazil ||
    !reportFrance
  ) {
    toast.error("Please wait for the data to load");
    return;
  }
  try {
    setLoading(true);
    const doc = new jsPDF();

    // Add WHO section
    await addWhoSection(doc, reportWHO);

    // Add Russia section
    await addRussiaSection(doc, reportRussia, russiaRef);

    // Add Italy section
    await addItalySection(doc, reportItaly, italyRef);

    // Add Spain section
    await addSpainSection(doc, reportSpain, spainRef);

    // Add China section
    await addChinaSection(doc, reportChina);

    // Add Indonesia section
    await addIndonesiaSection(doc, reportIndonesia, indonesiaRef);

    // Add Brazil section
    await addBrazilSection(doc, reportBrazil, brazilRef);

    // Add France section
    const franceChartImages = await franceRef.current.getChartImages();
    await addFranceSection(
      doc,
      reportFrance,
      franceChartImages.influenzaAgMapChart
    );

    // Save the PDF
    doc.save("comprehensive-report.pdf");
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    setLoading(false);
  }
};

export const getBase64Image = async (url) => {
  console.log("url: ", url);
  const proxyUrl = "https://cors-anywhere.herokuapp.com/";
  try {
    const response = await fetch(proxyUrl + url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject("Error converting image to Base64");
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error(error);
    return null;
  }
};
