import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGlobalNews } from "../../redux/slices/globalNews.slice";
import { Spinner, Alert, Button, Container } from "react-bootstrap";
import { format } from "date-fns";
import { LuRefreshCcw, LuArrowDownToLine } from "react-icons/lu";
import { handleDownloadPdf } from "../../utils/pdfGenerator";

import "./styles/globalNews.css";
import { Loader } from "../UI/Loader/Loader";

const GlobalNews = () => {
  const dispatch = useDispatch();
  const { news, whoOutbreaks, loading, error, lastUpdated, selectedDate } =
    useSelector((state) => state.globalNews);

  const [selectedCountry, setSelectedCountry] = useState("all");
  const [expandedOutbreak, setExpandedOutbreak] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const printRef = useRef();

  useEffect(() => {
    dispatch(fetchGlobalNews());
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(fetchGlobalNews(currentDate));
  };

  const handleDateSelect = (date) => {
    setCurrentDate(date);
    dispatch(fetchGlobalNews(date));
    setShowDatePicker(false);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), "EEEE, MMMM dd, yyyy");
  };

  const handleReadMore = (url) => {
    if (!url) return;
    window.open(url, "_blank");
  };

  const renderWHOOutbreaks = () => {
    return (
      <section className="mb-12">
        <div className="grid grid-cols-1 gap-4">
          {whoOutbreaks.map((outbreak, idx) => (
            <article
              key={idx}
              className="bg-white border-gray-200 hover:shadow-md transition-shadow duration-200"
            >
              <div className="p-3 border-bottom">
                <div className="flex justify-between items-start">
                  <h3 className="text-xl font-bold mb-2 flex-grow">
                    {outbreak?.title}
                  </h3>
                  <span className="badge text-bg-secondary">
                    {outbreak?.date}
                  </span>
                </div>
                <div
                  className={`mt-4 ${
                    expandedOutbreak === idx ? "" : "line-clamp-3"
                  }`}
                >
                  <p className="text-gray-800">{outbreak?.situation_summary}</p>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    type="button"
                    onClick={() => handleReadMore(outbreak?.url)}
                    className="btn btn-link"
                  >
                    Read more
                  </button>
                </div>
              </div>
            </article>
          ))}
        </div>
      </section>
    );
  };

  const renderNews = () => {
    if (selectedCountry === "who-outbreaks") {
      return renderWHOOutbreaks();
    }

    if (!news || typeof news !== "object") {
      return (
        <div className="text-center text-gray-600 mt-8">
          No news data available
        </div>
      );
    }

    const countries = Object.keys(news);

    if (countries.length === 0) {
      return (
        <div className="text-center text-gray-600 mt-8">
          No news found for any country
        </div>
      );
    }

    return countries?.map((country) => {
      if (selectedCountry !== "all" && selectedCountry !== country) return null;

      const countryArticles = news[country];
      if (!countryArticles || countryArticles.length === 0) return null;

      return (
        <section key={country} className="newspaper-section mb-12">
          <div className="flex items-center justify-between mb-6">
            <h2
              className="text-3xl font-serif mb-6 pb-2 border-b-2 border-black"
              style={{
                color: "#3187ae",
              }}
            >
              {country}
            </h2>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {countryArticles?.map((article, idx) => (
              <article
                key={idx}
                className={`newspaper-section mb-8 pb-8 border-b border-gray-300`}
              >
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">
                    {article.title.replace(/[\[\]]/g, "")}
                  </h3>
                  <p className="mb-4">
                    <span className="badge text-bg-secondary">
                      {format(new Date(article.timestamp), "MMMM dd, yyyy")}
                    </span>
                  </p>
                  <p></p>
                  <div className="article-content">
                    <p className="text-gray-800 leading-relaxed">
                      {article.summary.replace(/[\[\]]/g, "")}
                    </p>
                    <div className="mt-4 flex flex-wrap items-center justify-between border-bottom pb-2">
                      {article.source && (
                        <p className="text-sm italic text-gray-600">
                          Source:
                          <button
                            type="button"
                            onClick={() => handleReadMore(article?.url)}
                            className="btn btn-link"
                          >
                            {article?.source}
                          </button>
                        </p>
                      )}
                      {article.topics && (
                        <div className="d-flex align-items-center gap-2 mt-2">
                          {article.topics.map((topic, i) => (
                            <span key={i} className="badge text-bg-primary">
                              {topic}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </section>
      );
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Container className="max-w-7xl mx-auto py-8 px-4">
        <header className="bg-white rounded-lg mb-8 p-6">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-serif text-center mt-4 mb-6">
            Global Health Monitor
          </h2>
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="mb-4">
              <input
                type="date"
                className="form-control"
                id="dateInput"
                value={
                  currentDate ? currentDate.toISOString().split("T")[0] : ""
                }
                onChange={(e) => handleDateSelect(new Date(e.target.value))}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="d-flex items-center gap-4 mb-4">
              <select
                className="form-select border border-gray-300 rounded px-3 py-2 bg-white"
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <option value="all">All Countries</option>
                <option value="who-outbreaks">WHO Outbreak News</option>
                {news &&
                  Object.keys(news).map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
              </select>
              <Button
                variant="outline-secndary"
                onClick={handleRefresh}
                disabled={loading}
                className="px-4 py-2"
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <LuRefreshCcw />
                )}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() =>
                  handleDownloadPdf(news, whoOutbreaks, lastUpdated)
                }
                className="px-4 py-2"
              >
                <LuArrowDownToLine />
              </Button>
            </div>
          </div>
        </header>
        {error && (
          <Alert variant="danger" className="mb-6">
            {error.detail || error}
          </Alert>
        )}

        {loading ? <Loader /> : <div ref={printRef}>{renderNews()}</div>}

        <footer className="mt-12 pt-6 border-t border-gray-300 text-center text-gray-600">
          <p className="mt-5">© {new Date().getFullYear()} AI-Flu Monitor</p>
        </footer>
      </Container>
      o
    </div>
  );
};

export default GlobalNews;
