import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap"; // Assuming you're using Bootstrap for the modal

const PDFViewer = ({ pdfUrl }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      {/* Link to open the modal */}
      <div className="d-flex align-items-center mb-2">
        <i className="fa fa-link me-2"></i>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleOpenModal();
          }}
        >
          View PDF Report
        </a>
      </div>

      {/* Modal to display the PDF */}
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>PDF Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={pdfUrl}
            width="100%"
            height="500px"
            title="PDF Report"
            style={{ border: "none" }}
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PDFViewer;
