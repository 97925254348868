import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  report: null,
  loading: true,
  error: null,
};

export const fetchSpainReport = createAsyncThunk(
  "spain/fetchSpainReport",
  async ({ week = null, year = null }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/spain-influenza-report`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            week, // Pass the week parameter
            year, // Pass the year parameter
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch Spain report");
      }

      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  }
);

const spainSlice = createSlice({
  name: "spain",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpainReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpainReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSpainReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.report = null;
      });
  },
});

export default spainSlice.reducer;
