import React, { useState } from 'react';
import CommonLoginRegisterComp from './CommonLoginRegisterComp';
import { useNavigate } from 'react-router-dom';


const Register = () => {
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    password: '',
  });
  
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMessage('');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/register`, { // Ensure this URL is correct
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      navigate('/');
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please check your network connection and try again.');
    }
  };

  return (
    <CommonLoginRegisterComp
      title1="Sign Up"
      title2=" for an Account"
      isRegister={true}
      isReserpassword={false}
      errorMessage={errorMessage}
      buttonText="Sign Up"
      checkboxText="By creating an account you agree to the Terms & Conditions and Privacy Policy."
      linkText1="Already have an account?"
      linkText2="Login"
      linkUrl="/"
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      formData={formData}
    />
  );
}

export default Register;
