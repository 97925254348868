import html2canvas from "html2canvas";

/**
 * Adds Italy section to the PDF document
 * @param {Object} doc - PDF document instance
 * @param {Object} italyReport - Italy report data from Redux store
 * @param {Object} chartRefs - References to chart components
 * @returns {Promise<void>}
 */
export const addItalySection = async (doc, italyReport, italyRef) => {
  const margin = 20;
  const lineHeight = 7;
  const sectionSpacing = 15;

  try {
    // Validate inputs
    if (!doc) throw new Error("PDF document instance is required");
    if (!italyReport) {
      console.warn("Italy report data is missing");
      return;
    }

    // Start new page for Italy section
    doc.addPage();

    doc.setFont("helvetica", "normal");

    // Add header
    doc.setFontSize(24);
    doc.setTextColor(41, 128, 185);
    doc.text("Italy", margin, 40);

    // Add report metadata
    const { week_id, year, date_range } = italyReport;
    const week = parseInt(week_id.slice(5), 10);

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(
      [
        `Year: ${year || "N/A"}`,
        `Week: ${week || "N/A"}`,
        `Date Range: ${date_range?.start || "N/A"} - ${
          date_range?.end || "N/A"
        }`,
      ],
      margin,
      60
    );

    let yPosition = 100;
    // Add summary points
    if (italyReport.summary_points?.length > 0) {
      doc.setFontSize(14);
      doc.setTextColor(41, 128, 185);
      doc.text("Weekly Summary", margin, 90);

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);

      for (const point of italyReport.summary_points) {
        const lines = doc.splitTextToSize(
          point,
          doc.internal.pageSize.width - margin * 2
        );
        doc.text(lines, margin, yPosition);
        yPosition += lines.length * lineHeight + sectionSpacing;
      }
    }

    // Add charts if available
    if (italyRef?.current) {
      try {
        const chartImages = await italyRef.current.getChartImages();
        if (chartImages) {
          doc.addPage();
          doc.setFontSize(16);
          doc.setTextColor(41, 128, 185);
          doc.text("Surveillance Data Visualization", margin, 30);

          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          const imageWidth = pageWidth - margin * 2;
          const imageHeight = imageWidth * 0.5; // Maintain aspect ratio

          let yPosition = 50;

          for (const [name, imageData] of Object.entries(chartImages)) {
            if (imageData) {
              // Check if we need a new page
              if (yPosition + imageHeight > pageHeight - margin) {
                doc.addPage();
                yPosition = margin;
              }

              doc.addImage(
                imageData,
                "JPEG",
                margin,
                yPosition,
                imageWidth,
                imageHeight,
                undefined,
                "MEDIUM"
              );

              yPosition += imageHeight + 20;
            }
          }
        }
      } catch (error) {
        console.error("Error adding charts to PDF:", error);
      }
    }

    // Add metadata section
    if (italyReport.graph_data?.metadata) {
      doc.addPage();
      doc.setFontSize(14);
      doc.setTextColor(0, 51, 102);
      doc.text("Additional Information", margin, 40);

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      const { metadata } = italyReport.graph_data;

      doc.text(
        [
          `WHO Region: ${metadata.who_region}`,
          `Flu Season: ${metadata.flu_season}`,
          `Hemisphere: ${metadata.hemisphere}`,
          `Country Code: ${metadata.country_code}`,
        ],
        margin,
        60
      );
    }

    // Add source attribution
    doc.setFontSize(10);
    doc.setTextColor(128, 128, 128);
    doc.text(
      `Source: ${italyReport.source_url || "Not available"}`,
      margin,
      doc.internal.pageSize.height - 20
    );
  } catch (error) {
    console.error("Error generating Italy PDF section:", error);
    // Add error message to PDF
    doc.setFontSize(12);
    doc.setTextColor(255, 0, 0);
    doc.text(
      "Error generating Italy report section. Please try again later.",
      margin,
      40
    );
  }
};
