import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  report: null,
  loading: true,
  error: null,
};

export const fetchChinaReport = createAsyncThunk(
  "china/fetchChinaReport",
  async ({ week, year } = {}, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams();
      if (week) queryParams.append("week", week);
      if (year) queryParams.append("year", year);

      const url = `${
        process.env.REACT_APP_BACKEND_URL
      }/scrapping/get-china-report${
        queryParams.toString() ? "?" + queryParams.toString() : ""
      }`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to fetch China report");
      }
      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  }
);

const chinaSlice = createSlice({
  name: "china",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChinaReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChinaReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchChinaReport.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.report = null;
      });
  },
});

export default chinaSlice.reducer;
