import React, { useState } from 'react';
import CommonLoginRegisterComp from './CommonLoginRegisterComp';
import { useNavigate } from 'react-router-dom';

function Newpassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'new_password') setNewPassword(value);
    if (name === 'confirm_password') setConfirmPassword(value);
  };
  const navigate = useNavigate();


const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }
  
    // Extract token from URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
  
    // Send the new password and token to the backend for processing
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ new_password: newPassword, token }),
      });
  
      const result = await response.json();
      if (response.ok) {
        navigate('/');
        // Redirect to login or success page
      } else {
        setError(result.message || 'Password reset failed');
      }
    } catch (err) {
      setError('An error occurred');
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <CommonLoginRegisterComp
      title1="Reset Password"
      title2=""
      isRegister={false}
      isReserpassword={true}
      isnewpassword={true}
      errorMessage={error}
      buttonText={loading ? 'Resetting...' : 'Reset Password'}
      linkText1="Remembered your password?"
      linkText2="Login"
      linkUrl="/"
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      newPassword={newPassword}
      confirmPassword={confirmPassword}
    />
  );
}

export default Newpassword;