import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  report: null,
  loading: false,
  error: null,
  dataLoaded: false,
  lastFetched: null,
};

export const fetchData = createAsyncThunk(
  "who/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/scrapping/get-who-report`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const whoSlice = createSlice({
  name: "who",
  initialState,
  reducers: {
    refreshData(state) {
      state.loading = true;
      state.dataLoaded = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.report = action.payload;
        state.loading = false;
        state.dataLoaded = true;
        state.lastFetched = Date.now();
        state.error = null;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.dataLoaded = true;
      });
  },
});

export const { refreshData } = whoSlice.actions;
export default whoSlice.reducer;
